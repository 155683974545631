import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Selectors from "../../Selectors";
import ActionCreator from "../../ActionCreator";
import JsonParser from "../../Utils/JsonParser";
import { validateEmail } from "../../Utils/FormUtil";
import * as Widget from "../Widget2";

const delay = ms => new Promise((resolve, reject) => setTimeout(resolve, ms));

class ProfileForm extends React.Component {
  constructor(props) {
    super(props);
    let { profile } = this.props;
    let dataObj = JsonParser(profile.data, {});
    this.state = {
      user: {
        name: profile.name,
        email: dataObj.email ? dataObj.email : "",
        address: dataObj.address ? dataObj.address : "",
        phone: profile.phone
      },
      message: {
        display: false,
        failure: false,
        text: ""
      },
      showSpinner: false
    };
  }

  render() {
    let { user, message, showSpinner } = this.state;

    return (
      <Wrapper>
        <Container>
          <ProfileFormField css="margin-bottom: 40px;">
            <h3>姓名</h3>
            <input
              value={user.name}
              onChange={e =>
                this.setState({ user: { ...user, name: e.target.value } })
              }
            />
          </ProfileFormField>

          <ProfileFormField css="margin-bottom: 40px;">
            <h3>電子信箱</h3>
            <input
              value={user.email}
              onChange={e =>
                this.setState({
                  user: { ...user, email: e.target.value },
                  message: { ...message, display: false }
                })
              }
            />
          </ProfileFormField>

          <ProfileFormField css="margin-bottom: 40px;">
            <h3>地址</h3>
            <input
              value={user.address}
              onChange={e =>
                this.setState({ user: { ...user, address: e.target.value } })
              }
            />
          </ProfileFormField>

          <ProfileFormField css="margin-bottom: 40px;">
            <h3>聯絡電話</h3>
            <input
              value={user.phone}
              onChange={e =>
                this.setState({ user: { ...user, phone: e.target.value } })
              }
            />
          </ProfileFormField>

          {message.display && (
            <Message failure={message.failure}>{message.text}</Message>
          )}

          {showSpinner ? (
            <Widget.Spinner css="align-self: flex-end;" />
          ) : (
            <Widget.BasicButton
              css="align-self: flex-end;"
              onClick={this._submit}
            >
              更新
            </Widget.BasicButton>
          )}
        </Container>
      </Wrapper>
    );
  }

  _submit = () => {
    let { appActions } = this.props;
    let { user } = this.state;

    if (user.email !== "" && !validateEmail(user.email)) {
      this.setState({
        message: {
          display: true,
          failure: true,
          text: "電子信箱格式不正確"
        }
      });
      return;
    }

    let JSONdata = JSON.stringify({ email: user.email, address: user.address });
    let data = {
      name: user.name,
      phone: user.phone,
      data: JSONdata
    };

    this.setState({ showSpinner: true });
    delay(500)
      .then(() => appActions.editMyProfile(data))
      .then(() =>
        this.setState({
          showSpinner: false,
          message: { display: true, failure: false, text: "更新成功" }
        })
      )
      .catch(err => {
        console.warn(err);
        this.setState({
          showSpinner: false,
          message: {
            display: true,
            failure: true,
            text: "更新失敗！請再試一次或聯絡網站人員。"
          }
        });
      });
  };
}

const Wrapper = styled.div`
  width: 100%;
  min-height: 450px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 600px) {
    height: auto;
  }

  ${props => props.extraCss || ""};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ProfileFormField = styled.div`
  display: flex;
  align-items: center;

  & > h3 {
    flex-shrink: 0;
    width: 80px;
    margin-right: 15px;
    color: #343434;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
  }
  & > input {
    width: 100px;
    flex-grow: 1;
    border-radius: 5px;
    border: 1px solid #e9e9e9;
    padding: 5px 18px;
    background: #ffffff;
    letter-spacing: 2px;
    color: #343434;
    font-size: 16px;
    line-height: 30px;

    :focus {
      outline: none;
    }
  }

  ${props => props.css || ""};
`;

const Message = styled.div`
  align-self: flex-end;
  margin-bottom: 6px;
  font-size: 13px;
  color: ${props => (props.failure ? "red" : "blue")};
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state)
  }),
  ActionCreator
)(ProfileForm);
