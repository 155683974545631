import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import * as Widget from "../Components/Widget2";

const delay = ms => new Promise((resolve, reject) => setTimeout(resolve, ms));

class ChangePasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      new_password: "",
      check_password: "",
      message: {
        display: false,
        failure: false,
        text: ""
      },
      showSpinner: false
    };
  }

  render() {
    let {
      password,
      new_password,
      check_password,
      showSpinner,
      message
    } = this.state;

    return (
      <Wrapper>
        <Container>
          <ProfileFormField css="margin-bottom: 40px;">
            <h3>舊密碼</h3>
            <input
              type="password"
              value={password}
              onChange={e =>
                this.setState({
                  password: e.target.value,
                  message: { ...message, display: false }
                })
              }
            />
          </ProfileFormField>

          <ProfileFormField css="margin-bottom: 40px;">
            <h3>新密碼</h3>
            <input
              type="password"
              value={new_password}
              onChange={e =>
                this.setState({
                  new_password: e.target.value,
                  message: { ...message, display: false }
                })
              }
            />
          </ProfileFormField>

          <ProfileFormField css="margin-bottom: 40px;">
            <h3>確認新密碼</h3>
            <input
              type="password"
              value={check_password}
              onChange={e =>
                this.setState({
                  check_password: e.target.value,
                  message: { ...message, display: false }
                })
              }
            />
          </ProfileFormField>

          {message.display && (
            <Message failure={message.failure}>{message.text}</Message>
          )}

          {showSpinner ? (
            <Widget.Spinner css="align-self: flex-end;" />
          ) : (
            <Widget.BasicButton
              css="align-self: flex-end;"
              onClick={this._submit}
            >
              更新
            </Widget.BasicButton>
          )}
        </Container>
      </Wrapper>
    );
  }

  _submit = () => {
    let { appActions, profile } = this.props;
    let { password, new_password, check_password } = this.state;

    if (password === "" || new_password === "" || check_password === "") {
      this.setState({
        message: {
          display: true,
          failure: true,
          text: "所有欄位皆須填寫"
        }
      });
      return;
    } else if (new_password !== check_password) {
      this.setState({
        message: {
          display: true,
          failure: true,
          text: "新密碼和確認新密碼要一致喔!"
        }
      });
      return;
    }

    this.setState({ showSpinner: true });
    delay(500)
      .then(() =>
        appActions.changePassword({
          username: profile.user.username,
          password: password,
          newPassword: new_password
        })
      )
      .then(() =>
        this.setState({
          showSpinner: false,
          message: {
            display: true,
            failure: false,
            text: "更新成功！"
          }
        })
      )
      .catch(err => {
        console.warn(err);

        if (err.status === 401) {
          return this.setState({
            showSpinner: false,
            message: {
              display: true,
              failure: true,
              text: "舊密碼不正確"
            }
          });
        }

        return this.setState({
          showSpinner: false,
          message: {
            display: true,
            failure: true,
            text: "更新失敗！請再試一次或聯絡網站人員。"
          }
        });
      });
  };
}

const Wrapper = styled.div`
  width: 100%;
  min-height: 378px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 600px) {
    height: auto;
  }

  ${props => props.extraCss || ""};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ProfileFormField = styled.div`
  display: flex;
  align-items: center;

  & > h3 {
    flex-shrink: 0;
    width: 80px;
    margin-right: 15px;
    color: #343434;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
  }
  & > input {
    width: 100px;
    flex-grow: 1;
    border-radius: 5px;
    border: 1px solid #e9e9e9;
    padding: 5px 18px;
    background: #ffffff;
    letter-spacing: 5px;
    color: #343434;
    font-size: 16px;
    line-height: 30px;

    :focus {
      outline: none;
    }
  }

  ${props => props.css || ""};
`;

const Message = styled.div`
  align-self: flex-end;
  margin-bottom: 6px;
  font-size: 13px;
  color: ${props => (props.failure ? "red" : "blue")};
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state)
  }),
  ActionCreator
)(ChangePasswordForm);
