import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withPage } from "../../Page";
import Selectors from "../../Selectors";
import ActionCreator from "../../ActionCreator";
import ParseQuery from "../../Utils/ParseQuery";
import Login from "../../Components/Login";
import * as Widget from "../../Components/Widget2";
import ProfileForm from "../../Components/ProfileForm";
import OrderList from "../../Components/OrderList";
import ChangePasswordForm from "../../Components/ChangePasswordForm";

const TabTypes = {
  Profile: "Profile",
  OrderList: "OrderList",
  ChangePassword: "ChangePassword"
};

class ProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabType: TabTypes.Profile,
      showChangePasswordDialog: false
    };
  }

  componentDidMount() {
    let { location, appActions, navActions } = this.props;
    if (location.search.indexOf("data") > -1) {
      let dataEncode = location.search.slice(location.search.indexOf("data"));
      let queryParams = ParseQuery(dataEncode);
      let data = JSON.parse(decodeURIComponent(queryParams.data));

      if (data.token) {
        window.localStorage.setItem("token", data.token);
        appActions.autoLogin().then(() => console.log("social login success"));
        setTimeout(() => navActions.push(location.pathname), 1500);
      }
    }
  }

  render() {
    let { profile, appActions } = this.props;
    let { tabType } = this.state;

    if (!profile) {
      return (
        <Widget.MaxWidth width={800} css="padding: 20px">
          <Widget.Center>
            <Login.Button />
          </Widget.Center>
        </Widget.MaxWidth>
      );
    }

    return (
      <Wrapper>
        {profile && (
          <Widget.MaxWidth
            width={1160}
            css={`
              margin: 30px 0px 90px;
              padding: 20px;
              display: flex;
              width: 100%;
              @media screen and (max-width: 1024px) {
                flex-direction: column;
              }
            `}
          >
            <div className="sidebar">
              <h2>{this._limitText(profile.user.username)}</h2>
              <div className="line" />
              <div
                className={`tab ${
                  tabType === TabTypes.Profile ? "active" : ""
                }`}
                onClick={() => this.setState({ tabType: TabTypes.Profile })}
              >
                基本資料
              </div>
              <div
                className={`tab ${
                  tabType === TabTypes.OrderList ? "active" : ""
                }`}
                onClick={() => this.setState({ tabType: TabTypes.OrderList })}
              >
                訂單記錄
              </div>
              <div
                className={`tab ${
                  tabType === TabTypes.ChangePassword ? "active" : ""
                }`}
                onClick={() =>
                  this.setState({ tabType: TabTypes.ChangePassword })
                }
              >
                更新密碼
              </div>
              <Widget.BasicButton
                css={`
                  margin-top: 12px;
                  box-shadow: none;
                  background: white;
                  color: #0a6fb7;
                  border: 1px solid #0a6fb7;
                `}
                onClick={() => appActions.logout()}
              >
                登出
              </Widget.BasicButton>
            </div>

            <div className="content">
              {(() => {
                //Title
                switch (tabType) {
                  case TabTypes.Profile:
                    return <h2>基本資料</h2>;
                  case TabTypes.OrderList:
                    return <h2>訂單記錄</h2>;
                  case TabTypes.ChangePassword:
                    return <h2>更新密碼</h2>;
                  default:
                    return;
                }
              })()}

              <div>
                {(() => {
                  //Content
                  switch (tabType) {
                    case TabTypes.Profile:
                      return <ProfileForm />;
                    case TabTypes.OrderList:
                      return <OrderList />;
                    case TabTypes.ChangePassword:
                      return <ChangePasswordForm />;
                    default:
                      return;
                  }
                })()}
              </div>
            </div>
          </Widget.MaxWidth>
        )}
      </Wrapper>
    );
  }

  _limitText = data => {
    if (data.indexOf("@") >= 0) {
      return data.split("@")[0];
    }

    return data;
  };
}

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;

  & > div > .sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #e1e1e1;
    margin-right: 50px;
    padding: 50px 30px;
    max-width: 320px;
    width: 100%;

    & > h2 {
      padding-bottom: 22px;
      max-width: 200px;
      width: 100%;
      word-wrap: break-word;
      text-align: center;
      font-weight: 500;
      font-size: 30px;
      color: #575757;
    }

    & > .line {
      margin-bottom: 50px;
      background-color: #e1e1e1;
      width: 100%;
      height: 1px;
    }

    & > .tab {
      cursor: pointer;
      margin-bottom: 25px;
      padding-bottom: 4px;
      font-size: 16px;
      color: #575757;
    }

    & > .active {
      border-bottom: 1px solid #0a6fb7;
      color: #0a6fb7;
    }
  }

  & > div > .content {
    flex: 1;
    border: 1px solid #e1e1e1;
    padding: 50px;
    width: 100%;
    & > h2 {
      border-bottom: 1px solid #e1e1e1;
      padding-bottom: 20px;
      font-weight: 500;
      font-size: 30px;
      color: #575757;
    }

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;
      min-height: 200px;
    }
  }
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    flex-wrap: wrap;
    & > div > .sidebar {
      margin: 0 auto 60px auto;
      max-width: 758px;
    }
    & > div > .content {
      margin: 0 auto 100px auto;
      max-width: 758px;
    }
  }
  @media screen and (max-width: 414px) {
    & > div > .content {
      padding: 50px 20px;
    }
  }
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state)
    }),
    ActionCreator
  )(ProfilePage)
);
