import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Selectors from "../../Selectors";
import ActionCreator from "../../ActionCreator";
import AppConfig from "../../Config";
import * as Widget from "../Widget2";
import OrderItem from "../OrderItem";

const delay = ms => new Promise((resolve, reject) => setTimeout(resolve, ms));

class OrderList extends Component {
  state = {
    orders: [],
    fetchingData: true
  };

  componentDidMount() {
    let { appActions } = this.props;
    let { fetchingData } = this.state;

    delay(200)
      .then(() => appActions.getOrderList())
      .then(orders => this.setState({ orders }))
      .then(() => this.setState({ fetchingData: false }))
      .catch(err => {
        console.warn(err);
        this.setState({ fetchingData: false });
      });
  }
  render() {
    let { profile, navActions } = this.props;
    let { orders, fetchingData } = this.state;

    if (fetchingData) {
      return <Widget.Spinner />;
    }

    if (orders.length === 0) {
      return (
        <Wrapper>
          <div className="empty">
            <div className="text">
              <span className="highlight">{profile.name}</span>
              {"您好\n目前沒有任何訂單"}
            </div>
            <button onClick={() => navActions.push("/products")}>
              BUY NOW
            </button>
          </div>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        {orders
          .sort((a, b) => b.id - a.id)
          .map(o => (
            <OrderItem
              key={o.id}
              order={o}
              css={`
                margin-bottom: 10px;
              `}
            />
          ))}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: 100%;

  & > .empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    white-space: pre-wrap;
    text-align: center;

    & > .text {
      margin-bottom: 23px;
      letter-spacing: 1.25px;
      font-size: 20px;
      color: #575757;

      & > .highlight {
        margin: 0px 5px 18px 0px;
        color: gray;
      }
    }

    & > img {
      margin-bottom: 18px;
      width: 100px;
      height: 100px;
    }

    & > button {
      border: 0px;
      padding: 8px;

      cursor: pointer;
      min-width: 114px;
      background-color: gray;
      font-size: 14px;
      color: white;

      :focus {
        outline: none;
      }
    }
  }

  @media screen and (max-width: 780px) {
    max-width: 758px;
  }

  ${AppConfig["Components/OrderList"].css || ""}
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state)
  }),
  ActionCreator
)(OrderList);
