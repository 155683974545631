import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "gatsby";
import styled from "styled-components";
import ActionCreator from "../../ActionCreator";
import { dateParser } from "../../Utils/DateUtil";
import * as L from "../../Utils/Lang";
import * as Widget from "../Widget";
import PaymentButton from "../../Components/PaymentButton";

class OrderItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { css, order } = this.props;

    return (
      <Wrapper extraCss={css} to={`/order?id=${order.id}`}>
        <Widget.Row justify="space-between" extraCss="margin-bottom: 20px;">
          <Subtitle>{order.payment_transaction_id}</Subtitle>
          <Subtitle>{dateParser(order.created)}</Subtitle>
        </Widget.Row>
        <Title>{order.title}</Title>
        <Widget.Row extraCss="margin-top: 10px;">
          <Data style={{ marginRight: 5 }}>付款方式：</Data>
          <Data>{L.s(`payment-type-${order.payment_type}`)}</Data>
        </Widget.Row>
        <Widget.Row extraCss="margin-top: 10px;">
          <Data style={{ marginRight: 5 }}>取貨地點：</Data>
          <Data />
        </Widget.Row>
        <Widget.Row extraCss="margin-top: 10px;">
          <Data style={{ marginRight: 5, marginBottom: 5 }}>付款金額：</Data>
          <Data>{order.amount}元</Data>
        </Widget.Row>

        <Widget.Row align="flex-end" justify="flex-end">
          <Status
            success={order.payment_status === "success"}
            failure={order.payment_status === "failure"}
          >
            {L.s(`payment-${order.payment_status}`)}
          </Status>
        </Widget.Row>
      </Wrapper>
    );
  }
}

let Wrapper = styled(Link)`
  display: block;
  padding: 20px;
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid #e1e1e1;
  color: black;
  text-decoration: none;
  ${props => props.extraCss};
`;

let Subtitle = styled.div`
  color: #a4a4a4;
  font-size: 12px;
`;

let Title = styled.h3``;

let Status = styled.div`
  background-color: grey;
  padding: 10px 20px;
  color: white;
  font-size: 14px;
  ${props => props.success && "background-color: #87BB67 ;"};
  ${props => props.failure && "background-color: tomato;"};
`;

let Data = styled.div`
  color: #575757;
  margin-right: 5px;
`;

export default connect(
  null,
  ActionCreator
)(OrderItem);
